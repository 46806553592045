import React from 'react'
import logo from "../assets/images/logo.webp";
export default function MobileNavbar({PhoneNumber}) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className='MobileNavbar'>
      <div className="topFixed">
        <img src={logo} alt="" />
      </div>
      <div className="bottomFixed">
      
      <a  href={`tel:${telPhoneNumber}`}> Call Now: {PhoneNumber.PhoneNo}</a>
      
      </div>

    </div>
  )
}
