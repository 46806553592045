import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";

export default function HeroSection({ heroSection, refinance }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(heroSection.PhoneNo)}`;

  return (
    <div
      className="rel"
      style={{
        backgroundImage: `url(${refinance.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="Hero_Bg fix">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <div className="col-1-content">
                <div className="animate-from-left" ref={cardRefs[0]}>
                  <span>{heroSection.SubHeading}</span>
                </div>
                <div className="animate-from-right" ref={cardRefs[1]}>
                  <h1>{heroSection.Heading}</h1>
                </div>
                <div className="animate-from-right" ref={cardRefs[2]}>
                  <div>
                    <p dangerouslySetInnerHTML={{__html: heroSection.Text}}/>
                  </div>
                  <div className="btn-group desktop">
                    <a className="btn-filled" href={`tel:${telPhoneNumber}`}>
                      Call Now: {heroSection.PhoneNo}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
