import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
import Contact from "../components/Contact";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import img1 from '../assets/icons/reverse/1.png';
import img2 from "../assets/icons/reverse/2.png";
import img3 from "../assets/icons/choose/1.png";
import img4 from "../assets/icons/choose/2.png";
import img5 from "../assets/icons/choose/3.png";
import Refinnace from "../assets/photo/chalkboard-table-realtor-office.webp";
import HowItWorks from "../components/HowItWorks";
export default function Gg_colp1() {

  const heroSection = {
    SubHeading: "",
    Heading: ` Turn Home Equity 
into Financial Stability`,
Text:  `Find the Best <br/> Reverse Mortgage Options` ,
    PhoneNo: "(866) 406-0167",
  };
  const ourServices = [
    {
      icon: img3,
      Heading: "Experienced Professionals",
      Text: " Work with knowledgeable reverse mortgage experts.",
    },
    {
      icon: img4,
      Heading: "Tailored Solutions",
      Text: "Find options that align with your specific financial goals.",
    },
    {
      icon: img5,
      Heading: "Simplified Process",
      Text: " Enjoy a seamless experience from start to finish",
    },
  ];

  const HowitWorks = [
    {
      icon: "",
      Heading: "How We Can Help",
      Text: (
        <>
          We simplify the process of finding the right reverse mortgage provider. With our network of trusted professionals, you can confidently move forward with your financial decisions, knowing you have the best support available.
        </>
      ),
    },
  ];

  const PhoneNumber = {
    PhoneNo: "(866) 406-0167",
  };
  const ContactText = {
    heading: `Tap Into Your Home Equity
 with Reverse Mortgage Professionals`,
  };
  const refinance = {
    image: Refinnace,
  };
  return (
    <div>
      
      <Navbar PhoneNumber={PhoneNumber}/>
      <MobileNavbar PhoneNumber={PhoneNumber}/>
      <HeroSection refinance={refinance} heroSection={heroSection}/>
      <HowItWorks PhoneNumber={PhoneNumber} HowitWorks={HowitWorks}/>

      
      <Services ourServices={ourServices} PhoneNumber={PhoneNumber}/>
      <Contact Contact={ContactText} PhoneNumber={PhoneNumber}/>
      <Footer PhoneNumber={PhoneNumber}/>
    </div>
  );
}
