import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import img1 from './assets/icons/reverse/1.png';
import img2 from "./assets/icons/reverse/2.png";
import img3 from "./assets/icons/choose/1.png";
import img4 from "./assets/icons/choose/2.png";
import img5 from "./assets/icons/choose/3.png";
import Refinnace from './assets/photo/approved-mortgage-house-key.webp'
export default function landingPage() {
  const heroSection = 
    {
      SubHeading: "",
      Heading: ` Unlock Financial Freedom 
with a 
Reverse Mortgage`,
      Text: "Discover the Best Options for Your Needs",
      PhoneNo: "(833) 338-3062",

    }
    const ourServices =[
      {
        icon: img3,
        Heading: "Experienced Professionals",
        Text: " Work with knowledgeable reverse mortgage experts."

      },
      {
        icon: img4,
        Heading: "Tailored Solutions",
        Text: "Find options that align with your specific financial goals."

      },
      {
        icon: img5,
        Heading: "Simplified Process",
        Text: " Enjoy a seamless experience from start to finish"

      }
    ]
 

  
  const HowitWorks =[
    {
      icon: "",
      Heading: "WHY WOULD I NEED A REVERSE MORTGAGE?",
      Text: (
        <>
         You put in years of effort to increase the equity in your house. It’s time to use that equity to your advantage now. A reverse mortgage can improve your quality of life in retirement by reducing debt and providing funds to keep up with the cost of retirement living.
         <br />
         We simplify the process of finding the right reverse mortgage provider. With our network of trusted professionals, you can confidently move forward with your financial decisions, knowing you have the best support available.
        </>
      )

    },
   
  ]


  const PhoneNumber = {
     PhoneNo: "(833) 338-3062"

  }
  const ContactText ={
    heading: `Secure Your Financial Future
Contact Us for Expert 
Reverse Mortgage Guidance`
  }
  const refinance ={
    image: Refinnace
  }

  const whyChooseUs = [
    {
      icon: img1,
      Heading: "Reverse Mortgage",
      Text: "A reverse mortgage is a special loan that allows homeowners to leverage the equity in their home to borrow cash. The amount you can borrow depends on your age, the value of your property, and the interest rate. Repayment is only required upon your death or the sale of your property, with no monthly payments needed."

    },
    {
      icon: img2,
      Heading: "Creek Reverse Mortgage",
      Text: "With a Creek Reverse Mortgage loan, you can convert part of your home’s value into cash. The best part is that you can stay in your home for as long as you like without making monthly payments. If you're 62 or older and seeking financial stability in retirement, a Creek Reverse Mortgage could be the ideal solution."

    }
  ]
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber}/>
      <MobileNavbar PhoneNumber={PhoneNumber}/>
      <HeroSection refinance={refinance} heroSection={heroSection}/>
      <HowItWorks PhoneNumber={PhoneNumber} HowitWorks={HowitWorks}/>
      <WhyChooseUs whyChooseUs={whyChooseUs} PhoneNumber={PhoneNumber}/>
      
      <Services ourServices={ourServices} PhoneNumber={PhoneNumber}/>
      <Contact Contact={ContactText} PhoneNumber={PhoneNumber}/>
      <Footer PhoneNumber={PhoneNumber}/>
    </div>
  );
}
