import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(888) 627-0046",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="132"
        class="elementor elementor-132"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-411d3010 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="411d3010"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-55e58d87"
              data-id="55e58d87"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-62ecd26a elementor-widget elementor-widget-heading"
                  data-id="62ecd26a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Disclaimer
                    </h2>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-2334e5d6 elementor-widget elementor-widget-text-editor"
                  data-id="2334e5d6"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      <strong>Last updated&nbsp;July 25, 2022</strong>
                    </p>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-12f04a61 elementor-widget elementor-widget-text-editor"
                  data-id="12f04a61"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The information provided by Select Reverse Mortgage (“we,”
                      “us,” or “our”) on{" "}
                      <strong>
                        <a href="https://selectreversemortgage.com/">
                          SelectReverseMortgage.com
                        </a>
                      </strong>{" "}
                      (the&nbsp;“Site”)&nbsp;is for general informational
                      purposes only. All information on&nbsp;the Site&nbsp;is
                      provided in good faith, however we make no representation
                      or warranty of any kind, express or implied, regarding the
                      accuracy, adequacy, validity, reliability, availability,
                      or completeness of any information on&nbsp;the Site.{" "}
                      <strong>
                        UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU
                        FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
                        OF THE USE OF&nbsp;THE SITE&nbsp;OR RELIANCE ON ANY
                        INFORMATION PROVIDED ON&nbsp;THE SITE. YOUR USE
                        OF&nbsp;THE SITE&nbsp;AND YOUR RELIANCE ON ANY
                        INFORMATION ON&nbsp;THE SITE&nbsp;IS SOLELY AT YOUR OWN
                        RISK.
                      </strong>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-392072ad elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="392072ad"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1f219de4"
              data-id="1f219de4"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-33b7edff elementor-widget elementor-widget-text-editor"
                  data-id="33b7edff"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <h4>PROFESSIONAL DISCLAIMER</h4>
                    The Site cannot and does not contain Reverse Mortgage
                    advice. The Reverse Mortgage information is provided for
                    general informational and educational purposes only and is
                    not a substitute for professional advice. Accordingly,
                    before taking any actions based upon such information, we
                    encourage you to consult with the appropriate professionals.
                    We do not provide any kind of Reverse Mortgage advice.&nbsp;
                    <em>
                      <strong>
                        THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE
                        SITE IS SOLELY AT YOUR OWN RISK.
                      </strong>
                    </em>
                    <h4>TCPA DISCLAIMER</h4>
                    <p>
                      <span>
                        Notwithstanding any current or prior election to opt in
                        or opt out of receiving telemarketing calls, or SMS
                        messages (text messages) from our representatives,
                        agents, affiliates, you expressly consent to be
                        contacted by <strong>SELECT REVERSE MORTGAGE</strong>,
                        our agents, representatives, affiliates, or anyone
                        calling on our behalf. This is for any purposes relating
                        to your request for Reverse Mortgage service, at any
                        telephone number, or physical or electronic address you
                        provide or at which you may be reached. You agree we may
                        contact you in any way, including SMS messages (text
                        messages), calls using prerecorded messages or
                        artificial voice, and calls and messages delivered using
                        auto telephone dialing system or an automatic texting
                        system.
                      </span>
                    </p>
                    <p>
                      <span>
                        Automated messages may be played when the telephone is
                        answered whether by you or someone else. In the event
                        that an agent or representative of{" "}
                        <strong>SELECT REVERSE MORTGAGE</strong> calls, he or
                        she may also leave a message on your answering machine,
                        voice mail, or send one via text. You certify that the
                        telephone numbers that you have provided, are in fact
                        your contact numbers, and you agree to receive calls at
                        each phone number you have provided{" "}
                        <strong>SELECT REVERSE MORTGAGE</strong>. You agree to
                        promptly alert <strong>SELECT REVERSE MORTGAGE</strong>{" "}
                        in the event that you stop using a particular phone
                        number. Your cell/mobile telephone provider will charge
                        you according to the type of account you carry with
                        those companies.{" "}
                      </span>
                      <span>
                        You may opt out of any future contact via text message
                        by replying anytime with “STOP”.
                      </span>
                      <span>
                        {" "}
                        You agree that <strong>
                          SELECT REVERSE MORTGAGE
                        </strong>{" "}
                        may contact you by email, using any email address you
                        have provided or that you provide in the future.{" "}
                      </span>
                      <span>
                        You may opt out of any future contact via email message
                        by replying anytime with “UNSUBSCRIBE”.
                      </span>
                      <span>
                        <strong> SELECT REVERSE MORTGAGE</strong> may listen to
                        and/or record calls between you and any representative
                        without notice, as permitted by applicable laws. For
                        example, we listen to calls to monitor for quality
                        purposes.
                      </span>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
