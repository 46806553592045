// src/utils/scrollAnimation.js
class ScrollAnimation {
    constructor() {
      this.observer = new IntersectionObserver(this.handleIntersect, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      });
    }
  
    observeElement(element) {
      if (element) {
        this.observer.observe(element);
      }
    }
  
    handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          entry.target.classList.remove('animate');
        }
      });
    };
  }
  
  export default new ScrollAnimation();
  