import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Component/landingPage.js";
import ScrollToTop from "./Component/Utility/ScrollToTop.js";
// Home Inner Pages
import Bng from "./Component/Home_Inner_Pages/bng.js";
import Dl from "./Component/Home_Inner_Pages/dl.js";
import Fb from "./Component/Home_Inner_Pages/fb.js";
import Gg from "./Component/Home_Inner_Pages/gg.js";
import Rd from "./Component/Home_Inner_Pages/rd.js";
//Pages of Inner pages  LP1
import Bng_lp1 from "./Component/Home_Inner_Pages_lp1/bng_lp1.js";
import Dl_lp1 from "./Component/Home_Inner_Pages_lp1/dl_lp1.js";
import Fb_lp1 from "./Component/Home_Inner_Pages_lp1/fb_lp1.js";
import Gg_lp1 from "./Component/Home_Inner_Pages_lp1/gg_lp1.js";
import Rd_lp1 from "./Component/Home_Inner_Pages_lp1/rd_lp1.js";
import Gg_colp1 from "./Component/Home_Inner_Pages_lp1/gg_colp1.js";
//Pages of Inner pages  LP2
import Bng_lp2 from "./Component/Home_Inner_Pages_lp2/bng_lp2.js";
import Dl_lp2 from "./Component/Home_Inner_Pages_lp2/dl_lp2.js";
import Fb_lp2 from "./Component/Home_Inner_Pages_lp2/fb_lp2.js";
import Gg_lp2 from "./Component/Home_Inner_Pages_lp2/gg_lp2.js";
import Rd_lp2 from "./Component/Home_Inner_Pages_lp2/rd_lp2.js";
//Pages of Inner pages  LP3
import Bng_lp3 from "./Component/Home_Inner_Pages_lp3/bng_lp3.js";
import Dl_lp3 from "./Component/Home_Inner_Pages_lp3/dl_lp3.js";
import Fb_lp3 from "./Component/Home_Inner_Pages_lp3/fb_lp3.js";
import Gg_lp3 from "./Component/Home_Inner_Pages_lp3/gg_lp3.js";
import Rd_lp3 from "./Component/Home_Inner_Pages_lp3/rd_lp3.js";
//Website Generals Pages
import Contact from "./Component/WebsiteGeneralPages/Contact.js";
import PrivacyPolicy from "./Component/WebsiteGeneralPages/PrivacyPolicy.js";
import Disclaimer from "./Component/WebsiteGeneralPages/Disclaimer.js";
import TermsCondition from "./Component/WebsiteGeneralPages/TermsCondition.js";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Home Inner Pages */}
        <Route path="/bng" element={<Bng />} />
        <Route path="/dl" element={<Dl />} />
        <Route path="/fb" element={<Fb />} />
        <Route path="/gg" element={<Gg />} />
        <Route path="/rd" element={<Rd />} />
        {/* Pages of Inner Pages LP1 */}
        <Route path="/bng/lp1" element={<Bng_lp1 />} />
        <Route path="/dl/lp1" element={<Dl_lp1 />} />
        <Route path="/fb/lp1" element={<Fb_lp1 />} />
        <Route path="/gg/lp1" element={<Gg_lp1 />} />
        <Route path="/rd/lp1" element={<Rd_lp1 />} />
        <Route path="/gg/colp1" element={<Gg_colp1 />} />
        {/* Pages of Inner Pages LP2 */}
        <Route path="/bng/lp2" element={<Bng_lp2 />} />
        <Route path="/dl/lp2" element={<Dl_lp2 />} />
        <Route path="/fb/lp2" element={<Fb_lp2 />} />
        <Route path="/gg/lp2" element={<Gg_lp2 />} />
        <Route path="/rd/lp2" element={<Rd_lp2 />} />
        {/* Pages of Inner Pages LP3 */}
        <Route path="/bng/lp3" element={<Bng_lp3 />} />
        <Route path="/dl/lp3" element={<Dl_lp3 />} />
        <Route path="/fb/lp3" element={<Fb_lp3 />} />
        <Route path="/gg/lp3" element={<Gg_lp3 />} />
        <Route path="/rd/lp3" element={<Rd_lp3 />} />
        {/* Website General Pages */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-and-conditions" element={<TermsCondition />} />
      </Routes>
    </Router>
  );
}

export default App;
