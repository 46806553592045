import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import hero_img2 from "../../Component/assets/photo/money-saving-house-glass-bottle.webp";

export default function HowItWorks({ HowitWorks, PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="rel">
      <div className="WhyChooseUs HowWorks">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <div className="col-1-content">
                <div>
                {HowitWorks.map((item, index) => (
                    
                        <span>{item.Heading}</span>
                      
                  ))}
                </div>
                <div
                  className="card_container animate-from-left"
                  ref={cardRefs[2]}
                >
                  {HowitWorks.map((item, index) => (
                    <div className="card ">
                      <div className="card_content">
                        <p>{item.Text}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="btn-group">
                  <a className="btn-filled" href={`tel:${telPhoneNumber}`}>
                    {" "}
                    Call Now: {PhoneNumber.PhoneNo}
                  </a>
                </div>
              </div>
            </div>
            <div className="card  col-2">
              <div className="col-2-image">
                <img src={hero_img2} alt="hero side image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
